import ReactDOM from "react-dom";
import React from "react";

const parserReducer = (props, [currentKey, currentValue]) => {
  try {
    props[currentKey] = JSON.parse(currentValue);
  } catch (error) {
    props[currentKey] = currentValue;
  }
  return props;
};

const defaultParser = (dataset) =>
  Object.entries(dataset).reduce(parserReducer, {});

/**
 * Mounts the provided "Component" in the dom nodes returned by "selector" with the props
 * returned by passing the node dataset through mapDataToProps.
 *
 * @param {string} selector - selector to retrieve the dom nodes where the component will be mounted on.
 * A component will be mounted for each dom node returned by it
 * @param Component - Component to mount
 * @param {function} mapDataToProps - transforms the dom element into a object of props for the component.
 * Defaults to a "defaultParser" that tries to parse each dataset value as JSON and if that fails passes
 * as it is the dataset property as prop
 * @param {boolean} debug - If true, the data element and the props returned by mapDataTooProps will
 * be logged when mounting. Defaults to false
 */
const mountComponent = (
  selector,
  Component,
  mapDataToProps = defaultParser,
  debug = false
) => {
  const mount = (element) => {
    const props = mapDataToProps(element.dataset);
    // eslint-disable-next-line no-console
    if (debug) console.log({ element, props });
    ReactDOM.render(<Component {...props} />, element);
  };

  document.querySelectorAll(selector).forEach(mount);
};

export default mountComponent;
