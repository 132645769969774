import React, { useState, useEffect } from "react";
import ReactSelect from "react-select";

const arrayToOptions = (arr) =>
  arr.map((item) => ({ label: item, value: item }));

const MakerModel = ({ makers, maker, model }) => {
  const [selectedMaker, setMaker] = useState(maker)
  const [selectedModel, setModel] = useState(model ? { name: model } : {});
  const [models, setModels] = useState([]);

  const loadOptions = (inputValue) => {
    return fetch(`/api/v1/models?maker=${selectedMaker}`)
      .then((res) => res.json())
      .then((data) => setModels(data));
  };

  useEffect(() => {
    if (selectedMaker != "")  {
      loadOptions(null);
    }
  }, [selectedMaker]);

  const modelObj = models.filter((m) => m.name == selectedModel.name);

  return (
    <>
      <input type="hidden" name="q[maker_eq]" value={selectedMaker || ""} />
      <ReactSelect
        classNamePrefix="react-select"
        placeholder="Fabricante..."
        value={selectedMaker ? arrayToOptions([selectedMaker]) : null}
        options={arrayToOptions(makers)}
        onChange={(value) => setMaker(value.value)}
      />
      <input type="hidden" name="q[model_eq]" value={selectedModel.name} />
      <ReactSelect
        options={models}
        value={modelObj}
        placeholder="Modelo..."
        className="mt-2"
        onChange={(value) => setModel(value)}
        getOptionLabel={(e) => e.name}
        getOptionValue={(e) => e.name}
      />
    </>
  );
};

export default MakerModel;
